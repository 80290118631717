<template>
  <MainLayout
    :loading="loading"
    v-bind="$props"
    :actions="actions"
    :show-actions="hasPermissionForEditingOrder"
    v-on="$listeners"
    @action="actionsHandler"
  >
    <template #header>
      <h1>{{ $t('eventMapModal.orderCard.header') }}</h1>
      <div>{{ order.supplier.name || $t('commons.unknown') }}</div>
      <div>{{ formatDate(order.date) || $t('commons.unknownDate') }}</div>
    </template>
    <template v-if="hasOpenTask" #notification>
      <div class="task-notification">
        <div><NoticeIcon /></div>
        <div class="task-notification-content">
          <p>
            {{
              $t('eventMapModal.orderCard.taskNotification.taskContent.order.description', {
                count: taskInformation.differencesCount,
              })
            }}
          </p>
          <p>
            {{
              $t('eventMapModal.orderCard.taskNotification.taskContent.order.total', {
                amount: formatMoneyShekels(taskInformation.differencesAmount),
              })
            }}
          </p>
        </div>
        <div class="task-notification-actions">
          <Button class="task-action" @click="exportToPdf">
            {{ $t('eventMapModal.orderCard.taskNotification.taskActions.order.supplierRequest') }}
          </Button>
          <Button class="task-action" @click="handleTaskComplete">
            {{ $t('eventMapModal.orderCard.taskNotification.taskActions.order.taskComplete') }}
          </Button>
        </div>
      </div>
    </template>
    <template #content>
      <div class="h-100 overflow-scroll">
        <Boxes
          v-if="business"
          :order="order"
          :has-permission-for-editing-order="hasPermissionForEditingOrder"
          :business="business"
          @on-change-discount-rate="changeDiscountRate"
        />

        <ItemsTable
          class="overflow-scroll"
          :items="orderWithDifferenceItems.products"
          :order-id="eventId"
          :supplier-products="supplierProducts"
          :items-editable="hasPermissionForEditingOrder"
          @update="productToUpdate = $event"
          @solve-diff="diffProductIndex = $event"
        />
      </div>
      <OrderUpdateProductModal
        v-if="productToUpdate"
        :product="productToUpdate.product"
        :order-id="eventId"
        :order-date="order.date"
        :value="productToUpdate.value"
        :active-tab="productToUpdate.activeTab"
        :update-type="productToUpdate.updateType"
        :business-id="business.id"
        @update="handleUpdate"
        @close="productToUpdate = null"
      />
      <OrderDifferenceModal
        v-if="Number.isInteger(diffProductIndex)"
        :product-index="diffProductIndex"
        :order="orderWithDifferenceItems"
        :business="business"
        :after-submit="handleDifferenceResolve"
        @close="diffProductIndex = null"
      />
    </template>
  </MainLayout>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { isNil } from 'ramda';
import FileSaver from 'file-saver';

import { currency } from '@/locale/numberConfig';
import { options } from '@/locale/dateConfig';
import { NoticeIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { vatOf, addVat } from '@/modules/core/utils/vat';
import { useCompleteTask } from '@/modules/tasks';
import { useUserPermissions } from '@/modules/auth';
import { useOrderProductPatch, useOrderItemCreate, useOrderItemDelete, useOrderPatch } from '@/modules/order';
import { useCreateBillingFromDocument, useUncertainBillings } from '@/modules/billing';
import { useReconciliationTemplate } from '@/modules/reconciliation';
import { DOCUMENT_TYPES } from '@/modules/document/types';

import { ORDER_DIFFERENCE_PDF_MUTATION } from '../../compositions/order/graphql';
import { useBillings } from '../../compositions/billing';
import { useOrderData } from '../../compositions/order/useOrderData';
import OrderUpdateProductModal from './components/OrderUpdateProductModal.vue';
import { Boxes, ItemsTable, OrderDifferenceModal } from './components';
import { convertToPdfJson } from './tools/pdf';
import MainLayout from '../MainLayout';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';
import { useBusinessById } from '../../compositions';

const ACTION_EVENTS = {
  SUPPLIER_REQUEST: 'supplierRequest',
  CREATE_BILLING: 'createBilling',
};

export default {
  name: 'Order',
  components: { MainLayout, Boxes, ItemsTable, OrderUpdateProductModal, OrderDifferenceModal, NoticeIcon, Button },
  props: {
    eventId: { type: String, required: true },
    eventType: { type: String, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { completeTask } = useCompleteTask();
    const diffProductIndex = ref(null);
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForEditingOrder = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_ORDER);

    const currentId = computed(() => props.eventId);
    const { order, supplierProducts, loading, refetch, calcTotal } = useOrderData(currentId);

    const businessId = computed(() => order.value?.businessId);
    const { business } = useBusinessById(businessId);

    const differenceItems = computed(() => {
      const differencesThatHasNoCorrespondingOrderItem = order.value.differences?.filter((diff) =>
        isNil(diff.customerItemId)
      );

      return differencesThatHasNoCorrespondingOrderItem.map((diff, index) => {
        const price = !isNil(diff.price) ? 0 : null;
        const discount = !isNil(diff.discount) ? 0 : null;
        const quantity = !isNil(diff.quantity) ? 0 : null;
        const productIndex = order.value.products.length + index;

        return {
          differenceItemId: diff.supplierItemId,
          productIndex,
          product: diff.product,
          difference: {
            ...diff,
            productIndex,
          },
          price,
          discount,
          quantity,
          totalAmount: calcTotal(quantity, price, discount),
          source: {
            price: null,
            discount: null,
          },
          type: null,
          associatedTo: null,
        };
      });
    });

    const orderWithDifferenceItems = computed(() => ({
      ...order.value,
      products: order.value.products.concat(differenceItems.value),
      differences: order.value.differences.map((diff) => {
        if (!isNil(diff.supplierItemId) && isNil(diff.customerItemId)) {
          const differenceItem = differenceItems.value.find(
            (diffItem) => diffItem.differenceItemId === diff.supplierItemId
          );

          return {
            ...diff,
            productIndex: differenceItem.productIndex,
          };
        }
        return diff;
      }),
    }));

    const { patchOrderProduct } = useOrderProductPatch();
    const { onDone: createOrderItemDone } = useOrderItemCreate();
    const { deleteOrderItem } = useOrderItemDelete();
    const { orderPatch } = useOrderPatch();

    createOrderItemDone(refetch);

    const { mutate: generatePdf } = useMutation(ORDER_DIFFERENCE_PDF_MUTATION);
    const productToUpdate = ref(null);
    const handleUpdate = async (data) => {
      try {
        if (data.quantity === 0) {
          await deleteOrderItem({
            orderId: props.eventId,
            orderItemId: productToUpdate.value.id,
          });
        } else {
          await patchOrderProduct({
            orderId: props.eventId,
            productIndex: productToUpdate.value.index,
            data,
          });
        }
        productToUpdate.value = null;
        root.$message.success(root.$t('commons.messages.action.success'));
        await refetch();
      } catch (error) {
        console.error(error); //TODO: add to logger when UI will have one
        root.$message.error(root.$t('commons.messages.action.error'));
      }
    };

    const hasOpenTask = computed(() => order.value?.task && !order.value.task.completedAt);

    const taskInformation = computed(() => {
      const differencesCount = order.value?.differences.length;
      const differencesAmount = order.value?.differences?.reduce((acc, difference) => acc + difference.amount, 0);
      return {
        differencesCount,
        differencesAmount,
      };
    });

    const { reconciliationTemplate } = useReconciliationTemplate(
      computed(() => ({
        businessId: businessId.value,
        supplierId: order.value?.supplierId,
      }))
    );
    const { billings: relatedBillingsByDocumentId } = useBillings(
      computed(() => ({
        businessId: hasPermissionForEditingOrder.value ? businessId.value : null,
        eventRef: order.value?.eventReferences[0].documentId,
      }))
    );
    const { billings: relatedBillingsByOrderId } = useBillings(
      computed(() => ({
        businessId: hasPermissionForEditingOrder.value ? businessId.value : null,
        orderId: order.value?.id,
      }))
    );
    const { uncertainBillings } = useUncertainBillings(
      computed(() => ({ businessId: hasPermissionForEditingOrder.value ? order.value?.businessId : null }))
    );
    const relevantUncertainBilling = computed(() =>
      uncertainBillings.value.find(({ eventReferences }) =>
        eventReferences.some(({ documentId }) => documentId === order.value?.eventReferences?.[0].documentId)
      )
    );

    const getReason = () =>
      business.value?.countryCode !== 'US'
        ? root.$t(`billing.createFromDocument.disableReasons.notValidCountry`)
        : relatedBillingsByDocumentId.value?.length || relevantUncertainBilling.value
        ? root.$t(`billing.createFromDocument.disableReasons.sameSourceBillingExists`)
        : relatedBillingsByOrderId.value?.length
        ? root.$t(`billing.createFromDocument.disableReasons.connectedBillingExists`)
        : null;

    const actions = computed(() => [
      {
        event: ACTION_EVENTS.SUPPLIER_REQUEST,
        label: root.$t(`eventMapModal.orderCard.moreActions.${ACTION_EVENTS.SUPPLIER_REQUEST}`),
        disabled: !order.value?.differences.length,
      },
      {
        event: ACTION_EVENTS.CREATE_BILLING,
        label: root.$t(`eventMapModal.orderCard.moreActions.${ACTION_EVENTS.CREATE_BILLING}`),
        disabled:
          business.value?.countryCode !== 'US' ||
          !!relatedBillingsByOrderId.value?.length ||
          !!relatedBillingsByDocumentId.value?.length ||
          !!relevantUncertainBilling.value ||
          !order.value?.eventReferences.length,
        tooltipText: getReason(),
      },
    ]);

    const changeDiscountRate = async ({ discountRate }) => {
      const loader = root.$loading();
      try {
        await orderPatch({
          id: order.value.id,
          data: {
            discountRate,
          },
        });

        await refetch();
      } catch (error) {
        console.error(error);

        root.$message.error(root.$i18n.t('commons.messages.action.error'));
      } finally {
        loader.close();
      }
    };

    const formatMoneyShekels = (ms) =>
      typeof ms === 'number' && !Number.isNaN(ms) ? Number(ms).toLocaleString(root.$i18n.locale, currency) : '-';

    const formatDate = (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.long);

    const handleTaskComplete = async () => {
      const loader = root.$loading();
      try {
        await completeTask({ taskId: order.value.task.id });

        root.$message.success(root.$i18n.t('commons.messages.action.success'));
      } catch (error) {
        console.error(error); //TODO: add to logger when UI will have one

        root.$message.error(root.$i18n.t('commons.messages.action.error'));
      } finally {
        loader.close();
      }
    };

    const exportToPdf = async () => {
      const loader = root.$loading();
      try {
        const json = convertToPdfJson(business.value, orderWithDifferenceItems.value);
        const {
          data: {
            pdfGenerate: { url },
          },
        } = await generatePdf({
          template: 'orderDifferences',
          data: json,
        });
        const response = await fetch(url);
        const blob = await response.blob();
        FileSaver.saveAs(blob, `${json.reportName}.pdf`);

        root.$message.success(root.$i18n.t('commons.messages.action.success'));
      } catch (error) {
        console.error(error); //TODO: add to logger when UI will have one

        root.$message.error(root.$i18n.t('commons.messages.action.error'));
      } finally {
        loader.close();
      }
    };

    const handleDifferenceResolve = async () => {
      await refetch();
      if (order.value.task && !order.value.task.completedAt && order.value.differences.length === 0)
        await completeTask({ taskId: order.value.task.id });
    };

    const { createBillingFromDocument, onDone } = useCreateBillingFromDocument();
    onDone(refetch);

    const handleCreateBilling = async () => {
      const createBilling = async () => {
        const loader = root.$loading();
        try {
          const {
            data: { billingCreateFromDocument: created },
          } = await createBillingFromDocument({
            documentId: order.value.eventReferences[0].documentId,
            businessId: businessId.value,
          });
          if (created.id) {
            if (created.type === 'billing') {
              root.$message.success(root.$t(`billing.createFromDocument.messages.${created.type}`));
            } else {
              root
                .$alert(
                  root.$t(`billing.createFromDocument.messages.${created.type}`),
                  root.$t('billing.createFromDocument.confirm.title'),
                  {
                    confirmButtonText: root.$t('commons.apply'),
                  }
                )
                .catch(() => null);
            }
          } else root.$message.error(root.$t('billing.createFromDocument.messages.error'));
        } catch {
          root.$message.error(root.$t('billing.createFromDocument.messages.error'));
        } finally {
          loader.close();
        }
      };
      const {
        value: {
          eventReferences: [{ document }],
        },
      } = order;
      const { DELIVERY_NOTE, GOODS_RETURN_NOTE, PURCHASE_ORDER } = DOCUMENT_TYPES;
      const isDeliveryDocument = document.type === DELIVERY_NOTE || document.type === GOODS_RETURN_NOTE;
      const isPurchaseOrder = document.type === PURCHASE_ORDER;
      if (
        (isDeliveryDocument && reconciliationTemplate.value.createBillingFrom !== 'deliveryDocument') ||
        (isPurchaseOrder && reconciliationTemplate.value.createBillingFrom !== 'purchaseOrder')
      ) {
        root
          .$confirm(
            root.$t('billing.createFromDocument.confirm.text'),
            root.$t('billing.createFromDocument.confirm.title'),
            {
              confirmButtonText: root.$t('commons.apply'),
              cancelButtonText: root.$t('commons.cancel'),
              confirmButtonClass: 'el-button--primary',
              cancelButtonClass: 'el-button--secondary',
            }
          )
          .then(createBilling)
          .catch(() => null);
      } else await createBilling();
    };

    const actionsHandler = (event) => {
      switch (event) {
        case ACTION_EVENTS.SUPPLIER_REQUEST:
          exportToPdf();
          break;
        case ACTION_EVENTS.CREATE_BILLING: {
          handleCreateBilling();
          break;
        }
        default:
          break;
      }
    };

    const formatVAT = (value, onlyVat = false) =>
      !isNil(value)
        ? onlyVat
          ? formatMoneyShekels(vatOf(value))
          : formatMoneyShekels(addVat(value))
        : formatMoneyShekels(0);

    return {
      loading,
      business,
      order,
      supplierProducts,
      orderWithDifferenceItems,
      hasPermissionForEditingOrder,
      productToUpdate,
      diffProductIndex,
      hasOpenTask,
      taskInformation,
      actions,
      handleTaskComplete,
      handleUpdate,
      exportToPdf,
      handleDifferenceResolve,
      formatVAT,
      formatMoneyShekels,
      formatDate,
      changeDiscountRate,
      actionsHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../commons/style.scss';
p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 104%;
  margin: 0;

  [dir='ltr'] & {
    font-family: Inter;
  }

  [dir='rtl'] & {
    font-family: Rubik;
  }
}
.task-notification {
  margin: auto;
  background: $primary;
  color: $white;
  white-space: nowrap;

  border-radius: 6px;
  height: 64px;
  max-width: 800px;
  padding: 12px 16px;
  display: grid;
  gap: 10px;
  grid-template-columns: 24px 1fr 1fr;

  .task-notification-content {
    align-self: center;
    :first-child {
      margin: 5px 0px;
    }
  }
  .task-notification-actions {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .task-action {
      $btn-color: #5179eb;
      width: 100%;
      background: $btn-color;
      &:hover {
        background: darken($color: $btn-color, $amount: 3);
      }
      margin: 0;
    }
  }
}
</style>
