<template>
  <div class="event-modal-card-boxes">
    <DeleteDiscountRateModal
      v-if="currentDiscountRateAction === DISCOUNT_RATE_ACTIONS.DELETE"
      @on-close-modal="currentDiscountRateAction = undefined"
      @on-delete-discount-rate="() => onChangeDiscountRate({ discountRate: 0 })"
    />
    <ChangeDiscountRateModal
      v-if="
        currentDiscountRateAction === DISCOUNT_RATE_ACTIONS.ADD ||
        currentDiscountRateAction === DISCOUNT_RATE_ACTIONS.UPDATE
      "
      :is-add-discount="currentDiscountRateAction === DISCOUNT_RATE_ACTIONS.ADD"
      :current-discount-percentage="order.discountRate"
      @on-close-modal="currentDiscountRateAction = undefined"
      @on-change-discount-rate="onChangeDiscountRate"
    />
    <Box @mouseover.native="costBoxHover = true" @mouseleave.native="costBoxHover = false">
      <template #title>
        <div class="cost-box-title">
          <div>
            {{ $t('eventMapModal.orderCard.costBox.title') }}
          </div>
          <div v-if="hasPermissionForEditingOrder">
            <el-dropdown
              trigger="click"
              placement="bottom"
              @command="onChooseDiscountRateAction"
              @visible-change="(visible) => (discountRateActionVisible = visible)"
            >
              <Button
                type="icon"
                class="discount-rate-button"
                :class="{ 'discount-rate-action-active': costBoxHover || discountRateActionVisible }"
              >
                <KebabIcon :size="20" />
              </Button>
              <el-dropdown-menu>
                <el-dropdown-item v-if="!order.discountRate" :command="DISCOUNT_RATE_ACTIONS.ADD">
                  <div class="discount-rate-action">
                    <BoldPlusIcon />
                    <p>{{ $t('eventMapModal.orderCard.costBox.addDiscount') }}</p>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="order.discountRate" :command="DISCOUNT_RATE_ACTIONS.UPDATE">
                  <div class="discount-rate-action">
                    <EditIcon />
                    <p>{{ $t('eventMapModal.orderCard.costBox.updateDiscount') }}</p>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="order.discountRate" :command="DISCOUNT_RATE_ACTIONS.DELETE">
                  <div class="discount-rate-action delete">
                    <TrashCanIcon />
                    <p>{{ $t('eventMapModal.orderCard.costBox.deleteDiscount') }}</p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </template>
      <div class="cost-row">
        <span>
          {{ $t('eventMapModal.orderCard.costBox.totalProducts') }}
        </span>
        <span>
          {{ formatMoney(originalPriceBeforeDiscount) }}
        </span>
      </div>
      <div v-if="order.discountRate" class="cost-row">
        <span>
          {{ $t('eventMapModal.orderCard.costBox.discount', { percentage: order.discountRate * 100 }) }}
        </span>
        <span>
          {{ formatMoney(discountAmount * -1) }}
        </span>
      </div>
      <div class="cost-row">
        <span>
          {{ $t('commons.vat') }}
        </span>
        <span>
          {{ formatVAT(totalOrderPrice, 'true') }}
        </span>
      </div>
      <hr class="event-modal-card-boxes-hr--doted" />
      <div class="cost-row fw-bold">
        <span>
          {{ $t('commons.total') }}
        </span>
        <span>
          {{ formatVAT(totalOrderPrice) }}
        </span>
      </div>
    </Box>
    <Box :title="$t('eventMapModal.orderCard.detailsBox.title')">
      <div class="details-container">
        <div class="details-row">
          <div>
            <i class="el-icon-success" />
          </div>
          <div>
            <div>
              {{ $t('eventMapModal.orderCard.detailsBox.orderedBy') }}
              {{ order.orderedBy || $t('commons.unknown') }}
            </div>
            <div class="event-modal-card-date">
              {{ order.approvedAt ? formatDate(order.orderedAt) : $t('commons.unknownDate') }}
            </div>
          </div>
        </div>
        <div class="details-row">
          <div>
            <i class="el-icon-success" />
          </div>
          <div>
            <div>
              {{ $t('eventMapModal.orderCard.detailsBox.approvedBy') }}
              {{ order.approvedBy || $t('commons.unknown') }}
            </div>
            <div class="event-modal-card-date">
              {{ order.approvedAt ? formatDate(order.approvedAt) : $t('commons.unknownDate') }}
            </div>
          </div>
        </div>
      </div>
    </Box>
    <SummaryBox
      :documents="documents"
      :supplier-name="order.supplier.name"
      :customer-name="business.name"
      :type="summaryBoxType"
    >
      <div>
        {{ $t(`eventMapModal.orderCard.alertBox.${summaryBoxType}.matchingStatus`) }}
      </div>
      <div v-if="differencesObj.resultStatus === DIFFERENCES_STATES.PARTLY_SOLVED">
        <span>
          {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifferencePartial') }}
          {{
            $tc('eventMapModal.orderCard.alertBox.warning.existingDiffs', unsolvedDiffsCount, {
              count: unsolvedDiffsCount,
            })
          }}
          {{
            $t('eventMapModal.orderCard.alertBox.warning.existingDiffsAmount', {
              amount: unsolvedDiffsAmount,
            })
          }}
        </span>
        <span class="text-decoration-line-through">
          {{
            $tc('eventMapModal.orderCard.alertBox.warning.existingDiffs', totalDiffsCount, {
              count: totalDiffsCount,
            })
          }}
          {{
            $t('eventMapModal.orderCard.alertBox.warning.existingDiffsAmount', {
              amount: totalDiffsAmount,
            })
          }}
        </span>
      </div>
      <div v-if="differencesObj.resultStatus === DIFFERENCES_STATES.UNSOLVED">
        <span>
          {{
            $tc('eventMapModal.orderCard.alertBox.warning.existingDiffs', unsolvedDiffsCount, {
              count: unsolvedDiffsCount,
            })
          }}
          <span v-if="hasSomeUnknownUnsolvedAmount">
            {{ $t('eventMapModal.orderCard.alertBox.warning.unknown') + '.' }}
          </span>

          <span v-else>
            {{
              $t('eventMapModal.orderCard.alertBox.warning.existingDiffsAmount', {
                amount: totalDiffsAmount,
              })
            }}
          </span>
        </span>
      </div>
      <div v-if="differencesObj.resultStatus === DIFFERENCES_STATES.SOLVED">
        {{ $t('payment.paymentTable.expandableActivity.details.order.solvedDifference') }}
        <span class="text-decoration-line-through">
          {{
            $tc('eventMapModal.orderCard.alertBox.warning.existingDiffs', totalDiffsCount, {
              count: totalDiffsCount,
            })
          }}
          {{
            $t('eventMapModal.orderCard.alertBox.warning.existingDiffsAmount', {
              amount: totalDiffsAmount,
            })
          }}.
        </span>
      </div>
    </SummaryBox>
  </div>
</template>

<script>
import isNil from 'ramda/src/isNil';
import { ref, computed, getCurrentInstance } from 'vue';

import { useCurrency } from '@/locale/useCurrency';

import { formatCurrency } from '@/modules/purchase-management/purchaseManagementFormatters';

import { options } from '@/locale/dateConfig';
import { currency } from '@/locale/numberConfig';
import { vatOf, addVat } from '@/modules/core/utils/vat';

import { KebabIcon, BoldPlusIcon, EditIcon, TrashCanIcon } from '@/assets/icons';
import Button from '@/modules/core/components/Button';
import { SummaryBox } from '../../../commons/components';
import Box, { BOX_TYPE } from '../../../commons/components/Box';
import { getDifferencesSpecification, DIFFERENCES_STATES } from '../../../utils/differencesUtils';
import DeleteDiscountRateModal from './DeleteDiscountRateModal';
import ChangeDiscountRateModal from './ChangeDiscountRateModal';

const DISCOUNT_RATE_ACTIONS = {
  ADD: 'add',
  DELETE: 'delete',
  UPDATE: 'update',
};

export default {
  name: 'Boxes',
  components: {
    Box,
    SummaryBox,
    KebabIcon,
    Button,
    BoldPlusIcon,
    EditIcon,
    TrashCanIcon,
    DeleteDiscountRateModal,
    ChangeDiscountRateModal,
  },
  props: {
    order: { type: Object, required: true },
    hasPermissionForEditingOrder: { type: Boolean, default: true },
    business: { type: Object, required: true },
  },
  emits: ['on-change-discount-rate'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const { currencyFormat } = useCurrency();

    const costBoxHover = ref(false);
    const discountRateActionVisible = ref(false);
    const currentDiscountRateAction = ref(undefined);

    const differences = computed(() => {
      const diffTotals = props.order.differences.reduce((acc, difference) => {
        return acc + difference?.amount;
      }, 0);

      return {
        count: props.order.differences.length,
        totalAmount: Number(diffTotals).toLocaleString(root.$i18n.locale, currency),
      };
    });

    const differencesObj = computed(() => getDifferencesSpecification(props.order.differences));

    const summaryBoxType = computed(() =>
      differencesObj.value.resultStatus === DIFFERENCES_STATES.SOLVED ||
      differencesObj.value.resultStatus === DIFFERENCES_STATES.NO_DIFFERENCES
        ? BOX_TYPE.SUCCESS
        : BOX_TYPE.WARNING
    );

    const documents = computed(() => props.order.eventReferences?.map(({ document }) => document));

    const unsolvedDiffsCount = computed(
      () => differencesObj.value.unsolvedPriceDiff.length + differencesObj.value.unsolvedQuantityDiff.length
    );

    const unsolvedDiffsAmount = computed(() =>
      formatMoney(
        differencesObj.value.unsolvedPriceDiff.reduce((prev, curr) => prev + curr, 0) +
          differencesObj.value.unsolvedQuantityDiff.reduce((prev, curr) => prev + curr, 0)
      )
    );

    const totalDiffsCount = computed(
      () =>
        differencesObj.value.unsolvedPriceDiff.length +
        differencesObj.value.unsolvedQuantityDiff.length +
        differencesObj.value.solvedPriceDiff.length +
        differencesObj.value.solvedQuantityDiff.length
    );

    const totalDiffsAmount = computed(() =>
      formatMoney(
        differencesObj.value.unsolvedPriceDiff.reduce((prev, curr) => prev + curr, 0) +
          differencesObj.value.unsolvedQuantityDiff.reduce((prev, curr) => prev + curr, 0) +
          differencesObj.value.solvedQuantityDiff.reduce((prev, curr) => prev + curr, 0) +
          differencesObj.value.solvedQuantityDiff.reduce((prev, curr) => prev + curr, 0)
      )
    );

    const hasSomeUnknownUnsolvedAmount = computed(
      () =>
        differencesObj.value.unsolvedPriceDiff.some((e) => isNil(e)) ||
        differencesObj.value.unsolvedQuantityDiff.some((e) => isNil(e))
    );

    const discountAmount = computed(() => calculateDiscountAmount(props.order.products));
    const originalPriceBeforeDiscount = computed(() => {
      const netAmount = props.order.netAmount;
      const discountRate = props.order.discountRate ?? 0;

      return netAmount / (1 - discountRate);
    });

    const totalOrderPrice = computed(() => {
      if (isNil(props.order.netAmount)) return props.order.netAmount;
      if (isNil(discountAmount.value)) return props.order.netAmount;

      return props.order.netAmount - discountAmount.value;
    });

    const formatVAT = (value, onlyVat = false) =>
      !isNil(value) ? (onlyVat ? formatMoney(vatOf(value)) : formatMoney(addVat(value))) : formatMoney(value);

    const formatMoney = (value) => formatCurrency(value, currencyFormat.value) ?? root.$t('commons.unknown');

    const calculateDiscountAmount = (products) => {
      return products.reduce((acc, { orderDiscountAmount, quantity }) => {
        if (!orderDiscountAmount) return acc;
        return orderDiscountAmount * quantity + acc;
      }, 0);
    };

    const formatDate = (ms) => new Date(ms).toLocaleDateString(root.$i18n.locale, options.full);

    const onChooseDiscountRateAction = (type) => (currentDiscountRateAction.value = type);

    const onChangeDiscountRate = ({ discountRate }) => {
      emit('on-change-discount-rate', { discountRate });
      currentDiscountRateAction.value = undefined;
    };

    return {
      DIFFERENCES_STATES,
      DISCOUNT_RATE_ACTIONS,
      differencesObj,
      differences,
      costBoxHover,
      summaryBoxType,
      currentDiscountRateAction,
      documents,
      originalPriceBeforeDiscount,
      discountRateActionVisible,
      unsolvedDiffsCount,
      unsolvedDiffsAmount,
      totalDiffsCount,
      totalDiffsAmount,
      hasSomeUnknownUnsolvedAmount,
      discountAmount,
      totalOrderPrice,
      formatDate,
      formatVAT,
      formatMoney,
      calculateDiscountAmount,
      onChooseDiscountRateAction,
      onChangeDiscountRate,
    };
  },
};
</script>

<style scoped lang="scss">
@import '../../../commons/style';
@import '@/stylesheets/scss/global';
::v-deep {
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: black;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: black;

    .delete {
      color: #e52044;
    }
  }
}

.cost-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cost-box-title {
  font-weight: 500;
  font-size: 1.15em;
  display: flex;
  width: 100%;
  height: 2rem;
  justify-content: space-between;
}

.discount-rate-action {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.delete {
  color: #e52044;
}

.discount-rate-button {
  visibility: hidden;
}

.discount-rate-action-active {
  visibility: visible;
}

.details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.details-row {
  > div:first-child {
    padding-top: 2px;
    @at-root [dir='ltr'] #{&} {
      margin-right: 10px;
    }
    @at-root [dir='rtl'] #{&} {
      margin-left: 10px;
    }
  }

  display: flex;
  margin: 7px;
}
</style>
